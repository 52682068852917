// TODO прилетело с админки, подумать на рефакторе
export const errorStatus = {
  0: 'Поле не может быть пустым',
  REQUIRED: 'Обязательное поле',
  INVALID: 'Некорректное значение',
  LEN: 'Длина должна быть %d символов',
  DATE_YMD: 'Дата должна быть в формате ГГГГ-ММ-ДД',
  MIN: 'Минимальное значение: %d',
  MAX: 'Максимальное значение: %d',
  GT: 'Значение должно быть больше %d',
  GTE: 'Значение должно быть больше или равно %d',
  LT: 'Значение должно быть меньше %d',
  LTE: 'Значение должно быть меньше или равно %d',
  MIN_LEN: 'Длина должна быть больше %d символов',
  MAX_LEN: 'Длина должна быть меньше %d символов',
  TOO_FEW_ELEM: 'Минимальное количество элементов: %d',
  TOO_MANY_ELEM: 'Максимальное количество элементов: %d',
  REQUIRED_WITHOUT: 'Введите одно из полей',
  EMAIL: 'Недопустимый формат для email',
  EMAIL_LIST: 'Недопустимый формат для списка email',
};

// TODO прилетело с админки, подумать на рефакторе
export const commonError = {
  'Invalid JSON payload': 'Ошибка: некорректные данные',
  'Invalid request payload': '',
  BAD_REQUEST: '',
  'Entity already exists': 'Такой объект уже существует',
};

// TODO прилетело с админки, подумать на рефакторе
export function validation(name) {
  const index = this.validationStatus.findIndex((i) => i.key === name);
  if (index > -1) {
    let errorCode;
    if (this.validationStatus[index].value.indexOf('=') !== -1) {
      errorCode = this.validationStatus[index].value.split('=');
    } else {
      errorCode = this.validationStatus[index].value.split(':');
    }
    let errorText = errorStatus[errorCode[0]] || this.validationStatus[index].value || errorStatus.REQUIRED;
    if (errorCode.length > 1) {
      errorText = errorText.replace('%d', errorCode[1]);
    }
    return {
      key: this.validationStatus[index].key,
      value: errorText,
      status: true,
    };
  }
  return { key: false, value: '', status: false };
}

// TODO прилетело с админки, подумать на рефакторе
export function handleError(error) {
  const { response } = error;
  if (response.status === 400 && response.data.error.code === 'BAD_REQUEST') {
    this.validationStatus = response.data.error.data || [];
    const { message } = response.data.error;
    if (commonError[message] !== undefined) {
      this.globalErrorMessage = commonError[message];
    } else {
      this.globalErrorMessage = message || '';
    }
    return false;
  }
  if ([409, 422].includes(response.status) && ['CONFLICT', 'UNPROCESSABLE'].includes(response.data.error.code)) {
    this.validationStatus = response.data.error.data || [];
    return false;
  }
  return console.warn(error);
}
