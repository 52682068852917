<template>
  <CardWrapper
    :to="getRouteTo"
    v-bind="$attrs"
    class="fill-height pa-4"
    data-test="goal-card"
  >
    <VRow
      dense
      align="center"
      justify="space-between"
    >
      <VCol
        v-if="showDate"
        cols="auto"
        class="tt-light-mono-46--text tt-text-caption"
        data-test="goal-card-deadline-date"
      >
        <span class="text-lowercase">{{ $t('time.until') }}</span> {{ formattedDateDeadline }}
      </VCol>
      <VCol
        cols="auto"
      >
        <ChipStatus
          :status="goal.state"
          :date="goal.deadline"
          :item-type="$options.chipStatusTypes.PURPOSE"
          show-icon
        />
      </VCol>
    </VRow>
    <VRow dense>
      <VCol cols="12">
        <div
          class="tt-text-title-2"
          data-test="goal-card-title"
        >
          <slot
            name="title"
          >
            {{ title }}
          </slot>
        </div>
      </VCol>
    </VRow>
    <VRow dense>
      <VCol cols="12">
        <div
          class="tt-text-body-2"
          data-test="goal-card-description"
        >
          <slot
            name="description"
          >
            {{ description }}
          </slot>
        </div>
      </VCol>
    </VRow>
  </CardWrapper>
</template>

<script>

import CardWrapper from '@/components/cards/CardWrapper.vue';
import {
  taskType, COMPLETED, NEW, chipStatusTypes, GOAL_TYPES, GOAL_TITLE_MAX_LENGTH,
} from '@/helpers/constants';
import { cutString } from '@/helpers/stringHelpers';
import ChipStatus from '@/components/chips/ChipStatus.vue';

export default {
  name: 'GoalCardMobile',
  components: {
    ChipStatus,
    CardWrapper,
  },
  COMPLETED,
  taskType,
  chipStatusTypes,
  inject: ['Names'],
  props: {
    type: {
      type: String,
      default: GOAL_TYPES.personal,
      validator: (value) => [GOAL_TYPES.personal, GOAL_TYPES.trainee].includes(value),
    },
    userId: {
      type: [String, Number],
      default: 0,
    },
    expires: {
      type: String,
      default: '',
    },
    goal: {
      type: Object,
      default: () => ({}),
    },
    titleLength: {
      type: Number,
      default: GOAL_TITLE_MAX_LENGTH,
    },
  },
  computed: {
    title() {
      return cutString(this.goal?.name, this.titleLength);
    },
    description() {
      return this.goal?.description;
    },
    formattedDateDeadline() {
      if (!this.goal.deadline) {
        return null;
      }
      return `${this.$dayjs(this.goal.deadline).format('D MMMM YYYY')}`;
    },
    showDate() {
      return this.goal.state === NEW;
    },
    getRouteTo() {
      const goalId = this.goal.id;
      if (this.type === GOAL_TYPES.trainee) {
        const { userId } = this;
        return { name: this.Names.R_APP_TRAINEE_GOAL_VIEW, params: { type: GOAL_TYPES.trainee, userId, goalId } };
      }
      return { name: this.Names.R_APP_GOAL_VIEW, params: { goalId } };
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
