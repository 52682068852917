<template>
  <VContainer
    fluid
    class="pa-6"
  >
    <VRow>
      <VCol
        cols="12"
        sm="11"
        offset-sm="1"
      >
        <VRow>
          <VCol
            cols="12"
          >
            <VRow
              align="center"
              justify="space-between"
            >
              <VCol
                cols="auto"
              >
                <div
                  data-test="title-form"
                  :class="[$vuetify.breakpoint.xsOnly? 'tt-text-title-1':'tt-text-headline-1']"
                >
                  {{ edit ? $t('goals.title_edit') : $t('goals.title_add') }}
                </div>
              </VCol>
              <VCol
                cols="auto"
                class="text-center"
              >
                <VRow justify="center">
                  <VCol cols="auto">
                    <TTBtn
                      fab
                      color="white"
                      elevation="1"
                      large
                      class="mb-2"
                      @click="handleClose"
                    >
                      <VIcon
                        color="tt-black"
                        size="19"
                      >
                        fal fa-times
                      </VIcon>
                    </TTBtn>
                    <div
                      v-if="$vuetify.breakpoint.smAndUp"
                      class="tt-text-caption tt-black--text text--lighten-2"
                    >
                      {{ $t('close') }}
                    </div>
                  </VCol>
                </VRow>
              </VCol>
            </VRow>
          </VCol>
        </VRow>
        <VRow>
          <VCol
            cols="12"
            sm="8"
          >
            <VRow>
              <VCol cols="12">
                <TTTextField
                  v-model="fields.name"
                  large
                  :label="$t('goals.label_input_name')"
                  :placeholder="$t('goals.placeholder_input_name')"
                  :error="validation('name').status"
                  :error-messages="validation('name').value"
                  data-test-label="goal-form-input-name"
                  @input="v => handleChange('name', v)"
                />
              </VCol>
            </VRow>
            <VRow>
              <VCol cols="12">
                <TTTextarea
                  v-model="fields.description"
                  small
                  counter
                  hide-details
                  :rows="4"
                  :maxlength="1000"
                  :label="$t('goals.label_input_description')"
                  :placeholder="$t('goals.placeholder_input_description')"
                  :error="validation('description').status"
                  :error-messages="validation('description').value"
                  data-test-label="goal-form-textarea-description"
                  @input="v => handleChange('description', v)"
                />
              </VCol>
            </VRow>
            <VRow>
              <VCol
                cols="12"
                sm="3"
              >
                <SDatePicker
                  :label="$t('goals.label_input_deadline')"
                  attach
                  append-icon="fal fa-calendar-alt"
                  :placeholder="$t('goals.placeholder_input_deadline')"
                  :value="fields.deadline"
                  :error="validation('deadline').status"
                  :error-messages="validation('deadline').value"
                  :min-date="dateNow"
                  single-line
                  hide-details="auto"
                  data-test-label="goal-form-input-deadline-date"
                  @input="v => handleChange('deadline',v)"
                />
              </VCol>
            </VRow>
            <VRow
              class="mt-3"
            >
              <VCol
                cols="6"
                sm="auto"
              >
                <TTBtn
                  color="tt-secondary"
                  :disabled="saving"
                  :block="$vuetify.breakpoint.xsOnly"
                  large
                  depressed
                  data-test="button-cancel"
                  data-test-label="goal-form-btn-cancel"
                  @click="handleClose"
                >
                  {{ $t('cancel') }}
                </TTBtn>
              </VCol>
              <VCol
                cols="6"
                sm="auto"
              >
                <TTBtn
                  :disabled="saving"
                  :block="$vuetify.breakpoint.xsOnly"
                  :loading="saving"
                  depressed
                  large
                  data-test="button-save"
                  data-test-label="goal-form-btn-save"
                  @click="handleSave"
                >
                  {{ edit ? $t('save') : $t('create') }}
                </TTBtn>
              </VCol>
            </VRow>
          </VCol>
        </VRow>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import { validation, handleError } from '@/services';
import { purposeCreate, purposeUpdate } from '@/services/api/snami';
import SDatePicker from '@/components/ui/SDatePicker.vue';

export default {
  name: 'GoalForm',
  components: {
    SDatePicker,
  },
  props: {
    editFields: {
      type: Object,
      default: () => ({}),
    },
    edit: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      saving: false,
      validationStatus: [],
      fields: {
        id: null,
        name: '',
        description: '',
        deadline: '',
      },
      defaultFields: {},
    };
  },
  computed: {
    dateNow() {
      return this.$dayjs().format('YYYY-MM-DD');
    },
  },
  created() {
    if (this.edit) {
      this.setEditFields();
    } else {
      this.setDefaultFields();
    }
  },
  methods: {
    validation,
    handleError,
    handleClose() {
      this.$emit('close');
    },
    setEditFields() {
      this.fields = { ...this.editFields };
    },
    setDefaultFields() {
      this.defaultFields = { ...this.fields };
    },
    clearFields() {
      this.fields = { ...this.defaultFields };
    },
    handleChange(name, value, target) {
      this.fields[name] = value;
      const key = target || name;
      this.validationStatus = this.validationStatus.filter((i) => i.key !== key);
    },
    async handleSave() {
      const {
        id,
        name,
        description,
        deadline,
      } = this.fields;

      const data = {
        id,
        name,
        description,
        deadline,
      };

      try {
        this.saving = true;
        await (this.edit ? purposeUpdate({ data }) : purposeCreate({ data }));
        this.$emit('save');
        if (!this.edit) {
          this.clearFields();
        }
      } catch (e) {
        this.handleError(e);
      } finally {
        this.saving = false;
      }
    },
  },
};
</script>

<style>
/* stylelint-ignore no-empty-source */
</style>
