export const cutString = (string, length) => {
  if (!string) {
    return null;
  }
  if (string.length > length) {
    return `${string.slice(0, length)}...`;
  }
  return string;
};

export const currencyFormatter = (dataString) => dataString.toLocaleString('ru-Ru', {
  style: 'currency', currency: 'RUB', maximumFractionDigits: 0, minimumFractionDigits: 0,
});
