/* eslint-disable import/prefer-default-export */
import dayjs from 'dayjs';

export const dateToStr = (date, format = 'YYYY-MM-DD') => {
  if (date) {
    if (date instanceof Date) {
      return dayjs(date).format(format); // Форматируем, только если это дата
    }
    return date;
  }
  return '';
};
