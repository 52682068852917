<template>
  <TTTextField
    v-if="readonly || disabled"
    large
    :value="dateToStr(value, format)"
    readonly
    :class="contentClass"
    v-bind="customAttrs"
    :disabled="disabled"
  />
  <VMenu
    v-else
    v-model="menu"
    content-class="v-menu-shadow"
    :attach="attach"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="290px"
    @input="menu = false"
  >
    <template #activator="{ on }">
      <TTTextField
        ref="textInput"
        v-mask="mask"
        large
        :value="textDate"
        :error-messages="errorMessagesAttr"
        v-bind="customAttrs"
        autocomplete="off"
        :class="contentClass"
        @click="menu = true"
        @click:append="menu = true"
        v-on="on"
        @input="v => handleTextChange(v)"
        @keydown.tab="menu = false"
        @change="handleChange"
      />
    </template>
    <VCard>
      <VDatePicker
        v-model="date"
        :min="dateToCtkStr(computedMinDate)"
        :max="dateToCtkStr(computedMaxDate)"
        no-title
        v-on="listeners"
        @input="save($event)"
      />
    </VCard>
  </VMenu>
</template>
<script>
import { mask } from 'vue-the-mask';
import { dateToStr } from '@/utils';

const dateRegex = /[DdMmYy]/g;

export default {
  name: 'SDatePicker',
  directives: { mask },
  inheritAttrs: false,
  props: {
    value: {
      type: [Date, String],
      required: true,
    },
    minDate: {
      type: [Date, String],
      default: '',
    },
    maxDate: {
      type: [Date, String],
      default: '',
    },
    format: {
      type: String,
      default: 'YYYY-MM-DD',
    },
    displayFormat: {
      type: String,
      default: 'DD.MM.YYYY',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: [String, Array],
      default: () => ([]),
    },
    contentClass: {
      type: [String, Object, Array],
      default: '',
    },
    attach: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    errorMessage: '',
    textDate: '',
    textDateDirty: false,
    menu: false,
    date: '',
  }),
  computed: {
    MIN_DATE() {
      return this.$dayjs('1900-01-01');
    },
    MAX_DATE() {
      return this.$dayjs('2100-01-01');
    },
    computedMaxDate() {
      if (this.maxDate) {
        return this.dateToStr(this.maxDate);
      }
      return this.MAX_DATE.format(this.format);
    },
    computedMinDate() {
      if (this.minDate) {
        return this.dateToStr(this.minDate);
      }
      return this.MIN_DATE.format(this.format);
    },
    customAttrs() {
      const {
        readonly, disabled, format, maxDate, minDate, attach, ...others
      } = this.$attrs;
      return { ...others };
    },
    listeners() {
      const { input, change, ...others } = this.$listeners;
      return { ...others };
    },
    errorMessagesAttr() {
      if (!this.errorMessage) return this.errorMessages;
      if (Array.isArray(this.errorMessages)) {
        return this.errorMessages[0]
          ? this.errorMessages.concat(this.errorMessage) : [this.errorMessage];
      }
      return this.errorMessages ? [this.errorMessages, this.errorMessage] : [this.errorMessage];
    },
    mask() {
      return this.displayFormat.replace(dateRegex, '#');
    },
  },
  watch: {
    menu(val) {
      if (val && this.value) {
        this.date = this.dateToStr(this.value, this.format);
      }
    },
    value: {
      handler(val) {
        this.textDate = this.displayDate(val);
      },
      immediate: true,
    },
    minDate() {
      this.handleMinMaxChange();
    },
    maxDate() {
      this.handleMinMaxChange();
    },
  },
  methods: {
    dateToStr,
    save(v) {
      this.errorMessage = '';
      this.date = v;
      this.$emit('input', v);
    },
    displayDate(date) {
      if (date) {
        if (date instanceof Date) {
          return this.$dayjs(date).format(this.displayFormat);
        }
        return this.$dayjs(date, this.format).format(this.displayFormat);
      }
      return '';
    },
    dateToCtkStr(date) {
      return dateToStr(date, this.format);
    },
    validate(val) {
      this.textDateDirty = false;
      this.errorMessage = '';
      const date = this.$dayjs(val, this.displayFormat);
      if (!date.isValid()) {
        this.errorMessage = 'Неверный формат';
        return false;
      }
      if (date.format(this.displayFormat) !== val) {
        this.errorMessage = 'Неверный формат';
        return false;
      }
      if (date.add(1, 'day').isSameOrBefore(this.computedMinDate)) {
        this.errorMessage = 'Дата раньше допустимой';
        return false;
      }
      if (date.subtract(1, 'day')?.isSameOrAfter(this.computedMaxDate)) {
        this.errorMessage = 'Дата позже допустимой';
        return false;
      }
      return true;
    },
    handleTextChange(v) {
      this.errorMessage = '';
      this.textDateDirty = true;
      this.textDate = v;
      this.menu = true;
    },
    handleChange() {
      if (!this.textDateDirty) return;
      if (!this.textDate) {
        this.save('');
      } else if (this.validate(this.textDate)) {
        this.save(this.$dayjs(this.textDate, this.displayFormat).format(this.format));
      }
    },
    handleMinMaxChange() {
      if (this.textDate && this.validate(this.textDate)) {
        const newDate = this.$dayjs(this.textDate, this.displayFormat).format(this.format);
        if (newDate !== this.value) {
          this.save(newDate);
        }
      }
    },
  },
};
</script>

<style>
/* fix: тень для v-menu */
.v-menu-shadow {
  overflow: visible;
  contain: none;
}
</style>
